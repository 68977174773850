<template>
  <div class="auth-container">
    <div class="auth-container__header">
      <h1>
        {{ tosError ? $t('main.changeNotice') : $t('main.loginTitle') }}
      </h1>
      <p v-if="!tosError">{{ $t('main.loginGreeting') }}</p>
    </div>
    <div v-if="tosError" class="auth-container__notice">
      <p>
        {{ $t('main.changeNotice.notice') }}
        <a target="_blank" rel="noopener" :href="$whitelabel.links.termsOfService" @click.stop>
          {{ $t('main.termsOfService') }}
        </a>
        {{ ' ' }}{{ $t('main.and') }}
        <a target="_blank" rel="noopener" :href="$whitelabel.links.dataPrivacy" @click.stop>
          {{ $t('cart.privacyPolicy') }}
        </a>
        .
      </p>
      <p>
        {{ $t('main.changeNotice.info') }}
      </p>
      <BButton fill block :disabled="!terms" @click.native="login()">
        {{ $t('actions.continue') }}
      </BButton>
      <div class="auth-container__terms">
        <input
          id="tos"
          v-model="terms"
          type="checkbox"
          class="auth-container__terms-input"
        />
        <label for="tos" class="auth-container__terms-label">
          {{ $t('main.changeNotice.checkboxLabel') }}
          <a target="_blank" rel="noopener" :href="$whitelabel.links.dataPrivacy" @click.stop>
            {{ $t('main.dataPrivacy') }}
          </a>
          {{ ' ' }}{{ $t('main.and') }}
          <a
            target="_blank"
            rel="noopener"
            :href="$whitelabel.links.termsOfService"
            @click.stop
          >
            {{ $t('main.termsOfService') }}
          </a>
        </label>
      </div>
    </div>
    <div v-else class="auth-container__form">
      <div v-if="loginError" class="auth-container__error">
        <span>
          {{ loginError }}
        </span>
      </div>
      <form action="#" onsubmit="return false">
        <BInputField
          v-model="username"
          :label="$t('fields.usernameOrEmail')"
          type="username"
          compact
          no-validate
          data-cy="loginEmail"
          @hasError="formError"
        />
        <BInputField
          v-model="password"
          :label="$t('fields.password')"
          right-icon="show"
          type="password"
          compact
          no-validate
          :info-text="$t('main.forgotPassword')"
          info-text-clickable
          right-info
          data-cy="loginPassword"
          @infoTextClicked="gotoForgot()"
          @hasError="formError"
        />
        <div class="auth-container__login-buttons">
          <BButton
            fill
            block
            :disabled="hasError || loginDisabled"
            data-cy="submitLoginButton"
            @click.native="login()"
          >
            {{ $t('main.login') }}
          </BButton>
          <BButton
            v-if="getEnableGuestLogin"
            fill
            block
            @click.native="loginGuest()"
          >
            {{ $t('login.guestUser') }}
          </BButton>
        </div>
        <div v-if="ssoEnabled" class="auth-container__sso">
          <PDivider
            type="dashed"
            layout="horizontal"
            align="center"
            class="divider-spacing"
          >
            <span class="uppercase">{{ $t('main.or') }}</span>
          </PDivider>
          <div class="auth-container__sso--icons">
            <BAppleSignIn
              v-if="$config.features.enableAppleSSO"
              @login-success="handleAppleLogin"
              @login-failure="handleAppleLoginError"
            />
            <BGoogleSignIn
              v-if="$config.features.enableGoogleSSO"
              @login-success="handleGoogleLogin"
            />
            <BFacebookSignIn
              v-if="$config.features.enableFacebookSSO"
              @login-success="handleFacebookLogin"
            />
          </div>
        </div>
      </form>
      <div class="auth-container__register">
        <p>
          {{ $t('main.noAccount') }}
          <a data-cy="goToRegisterLink" @click.stop.prevent="gotoRegister()">{{
            $t('main.register')
          }}</a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PDivider from 'primevue/divider';
import { JwtCredentials, OAuthProvider } from '@bam/sdk';
import BInputField from '@/components/general/BInputField.vue';
import BButton from '@/components/general/BButton.vue';
import BAppleSignIn from '@/components/general/BAppleSignIn.vue';
import BGoogleSignIn from '@/components/general/BGoogleSignIn.vue';
import BFacebookSignIn from '@/components/general/BFacebookSignIn.vue';
import { parseJwt, errorMessage, getOrganizers } from '~/utils/utils';

export default {
  name: 'BLogin',
  components: {
    BInputField,
    BButton,
    PDivider,
    BAppleSignIn,
    BGoogleSignIn,
    BFacebookSignIn
  },
  data() {
    return {
      username: '',
      password: '',
      hasError: false,
      loginError: null,
      tosError: false,
      terms: false
    };
  },
  computed: {
    loginDisabled() {
      return !this.username || !this.password;
    },
    ...mapGetters('app', ['getEnableGuestLogin']),
    ...mapGetters('user', ['canAccessDashboard']),
    ssoEnabled() {
      return (
        this.$config.features.enableAppleSSO ||
        this.$config.features.enableGoogleSSO ||
        this.$config.features.enableFacebookSSO
      );
    }
  },
  methods: {
    async handleGoogleLogin(response) {
      try {
        await this.externalLogin(response?.credential, OAuthProvider.Google);
      } catch (e) {
        this.$notifier.showMessage({
          content: errorMessage(this.$t('errors.genericRequest'), e),
          type: 'error'
        });
        this.$router.push(this.localePath('/'));
      }
    },
    async handleFacebookLogin(response) {
      try {
        await this.externalLogin(response, OAuthProvider.Facebook);
      } catch (e) {
        this.$notifier.showMessage({
          content: errorMessage(this.$t('errors.genericRequest'), e),
          type: 'error'
        });
        this.$router.push(this.localePath('/'));
      }
    },
    async handleAppleLogin(response) {
      try {
        await this.externalLogin(
          response?.authorization?.id_token,
          OAuthProvider.Apple
        );
      } catch (e) {
        this.$notifier.showMessage({
          content: errorMessage(this.$t('errors.genericRequest'), e),
          type: 'error'
        });
        this.$router.push(this.localePath('/'));
      }
    },
    handleAppleLoginError(error) {
      this.$notifier.showMessage({
        content: errorMessage(error, this.$t('errors.genericRequest')),
        type: 'error'
      });
      this.$router.push(this.localePath('/'));
    },
    formError(e) {
      this.hasError = e;
    },
    async login() {
      this.loginError = null;
      this.tosError = null;
      try {
        const res = await this.$auth.loginWith('local', {
          data: {
            username: this.username,
            password: this.password,
            accepted_latest_tos: this.terms ? true : undefined
          }
        });
        if (res.data) {
          await this.setAuth(res);
          document.body.style = '';
        }
        this.$nuxt.$emit('onLogin');
      } catch (error) {
        if (error.response.data.error === 'account.user.tos_not_accepted') {
          this.tosError = true;
        } else {
          this.loginError = this.$t('login.error');
        }
      }
    },
    async loginGuest() {
      this.loginError = null;
      this.tosError = null;
      try {
        const resLogin = await this.$bamApi.auth.guestLogin();
        await this.$bamApi.authorize(
          new JwtCredentials({
            token: resLogin.token
          })
        );
        this.$auth.setUserToken('Bearer ' + resLogin?.token);
        this.$auth.setUser(parseJwt(resLogin?.token));
        this.$api.setHeader('Authorization', 'Bearer ' + resLogin?.token);
        this.$store.commit('app/setAuthDialog', false);
        this.$store.commit('app/setEnableGuestLogin', false);
        document.body.style = '';
        this.$nuxt.$emit('onLogin');
      } catch (err) {
        this.loginError = this.$t('login.error');
      }
    },
    async externalLogin(externalToken, provider) {
      this.loginError = null;
      this.tosError = null;
      try {
        const res = await this.$bamApi.auth.externalLogin({
          thirdParty: provider,
          token: externalToken,
          acceptedLatestTos: true
        });
        // Set SDK auth
        await this.$bamApi.authorize(
          new JwtCredentials({
            token: res.token,
            refreshToken: res.refreshToken
          })
        );
        // Set services auth
        await this.$auth.setUserToken(
          'Bearer ' + res?.token,
          res?.refreshToken
        );
        this.$api.setHeader('Authorization', 'Bearer ' + res?.token);
        await this.fetchUserInfo(res?.token);
        this.$store.commit('app/setAuthDialog', false);
        document.body.style = '';
        this.$nuxt.$emit('onLogin');
      } catch (err) {
        this.loginError = this.$t('login.error');
      } finally {
        this.$router.push(this.localePath('/'));
      }
    },
    async fetchUserInfo(token) {
      const tokenInfo = parseJwt(token);
      const user = await this.$bamApi.account.getUser({ id: tokenInfo.id });
      this.$auth.setUser({
        ...tokenInfo, // Token info goes first since the permissions property in it does not have the full permission list
        ...user
      });
      this.setLanguage(user?.localeCode);
      this.$store.commit('user/setUser', user);
      await this.getFilteredOrganizers();
    },
    setLanguage(localeCode) {
      if (localeCode) {
        this.$i18n.locale = localeCode;
        this.$router.push({ path: this.switchLocalePath(localeCode) });
      }
    },
    async getFilteredOrganizers() {
      try {
        const organizers = await getOrganizers(
          this.$auth.user.permissions,
          this.$bamApi,
          this.canAccessDashboard,
          this.$mainOrganizer
        );
        this.$store.commit('app/setOrganizers', organizers);
        this.$store.commit('app/setOrganizer', organizers[0]);
      } catch (e) {
        this.$notifier.showMessage({
          content: errorMessage(e, this.$t('errors.genericRequest')),
          type: 'error'
        });
      }
    },
    async setAuth(res) {
      const token = res?.data?.data?.token;
      const refreshToken = res?.data?.data?.refreshToken;
      // Set SDK auth
      await this.$bamApi.authorize(
        new JwtCredentials({
          token,
          refreshToken
        })
      );
      await this.$auth.setUserToken('Bearer ' + token, refreshToken);
      this.$api.setHeader('Authorization', 'Bearer ' + token);
      await this.fetchUserInfo(token);
      this.$store.commit('app/setAuthDialog', false);
    },
    gotoRegister() {
      this.$emit('gotoRegister');
    },
    gotoForgot() {
      this.$emit('gotoForgot');
    }
  }
};
</script>
<style lang="scss" scoped>
.uppercase {
  text-transform: uppercase;
}
.divider-spacing {
  margin-top: 24px;
  margin-bottom: 21px;
}
.google-sso {
  cursor: pointer;
}
</style>

<style lang="scss">
// styles for the apple login button because for some reason the component class prop won't work
.vue-apple-signin {
  display: inline-block;
  div {
    height: 45px !important;
    border-radius: 4px;
  }
}
</style>
