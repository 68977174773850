<template>
  <div
    class="header__wrapper"
    :class="{
      'header__wrapper--dashboard': isDashboard,
      active: (isScrolled || isFixed) && !isDashboard
    }"
  >
    <header
      :style="defaultPadding"
      class="header"
      :class="{ 'header--maxWidth': hasMaxWidth }"
    >
      <div v-if="!isDashboard" class="header__logo" @click="redirectToIndex">
        <img
          src="~/assets/img/logo.svg"
          class="header__logo desktop"
          alt="Logo"
        />
        <img
          src="~/assets/img/logo_footer.svg"
          class="header__logo mobile"
          alt="Logo"
        />
      </div>
      <div class="desktop">
        <BImpersonated v-if="isImpersonated" />
      </div>
      <div class="header__action">
        <div class="header__mobile">
          <IconMenu v-if="!mobileMenu" @click.native="switchMenu()" />
          <IconCancel v-else @click.native="switchMenu()" />
        </div>
        <nav
          class="header__nav"
          :class="{
            active: mobileMenu,
            'header__nav--info info-page': isInfoPage
          }"
        >
          <div v-if="!isInfoPage">
            <div class="header__search">
              <div class="header__search-wrapper">
                <input
                  key="search-input"
                  v-model="searchTerm"
                  type="search"
                  :placeholder="$t('search.events')"
                  autocomplete="off"
                  @keyup.esc="fetchEvents()"
                  @keyup.enter="fetchEvents()"
                />
                <div class="header__search-action" @click="fetchEvents()">
                  <IconSearch />
                </div>
              </div>
            </div>
            <div class="links">
              <a class="header__link" :href="localePath('/')">{{
                $t('main.home')
              }}</a>
              <div
                v-if="isOrganizer"
                class="header__link mobile"
                @click="redirectToDashboardInfoPage"
              >
                {{ $t('main.dashboard') }}
              </div>
              <a
                class="header__link"
                :href="$whitelabel.links.website"
                target="_blank"
                >{{ $t('main.aboutUs') }}</a
              >
              <a
                v-if="isOrganizer"
                class="header__link desktop"
                data-cy="organizerDashboard"
                :href="
                  localePath(getDashboardUrl(getOrganizer.name, $mainOrganizer))
                "
              >
                {{ $t('main.dashboard') }}
              </a>
              <BLoggedUser
                v-if="isLoggedIn && getUserEmail"
                :light="!isDashboard"
                :email="getUserEmail"
                @profile="redirectToProfile"
                @password="redirectToPassword"
                @privacy="redirectToPrivacy"
                @logout="logout"
              >
              </BLoggedUser>
              <a
                v-else
                class="header__link"
                data-cy="loginButton"
                @click="login()"
                >{{ $t('main.login') }}</a
              >
              <BLanguageSwitcher :light="!isDashboard"></BLanguageSwitcher>
            </div>
          </div>
          <div v-else>
            <h1 class="MOBILE_h22_Semibold info-page__title">
              {{ infoPageTitle }}
            </h1>
            <div class="MOBILE_h13_Regular info-page__text">
              {{ infoPageText }}
            </div>
            <div class="info-page__back">
              <IconBack />
              <span @click="redirectBack">{{
                $t('mobileMenu.infoPage.back')
              }}</span>
            </div>
          </div>
          <div class="footer">
            <div class="footer__title">{{ $t('mobileMenu.footer.title') }}</div>
            <div class="footer__content">
              {{ $t('mobileMenu.footer.content') }}
            </div>
            <div class="footer__image-container">
              <GooglePlay @click="redirectToGoogleStore" />
              <AppStore @click="redirectToAppleStore" />
            </div>
          </div>
        </nav>
      </div>
    </header>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import IconMenu from '~/components/general/icons/IconMenu.vue';
import IconSearch from '~/components/general/icons/IconSearch.vue';
import IconBack from '~/components/general/icons/IconBack.vue';
import IconCancel from '~/components/general/icons/IconCancel.vue';
import GooglePlay from '~/components/common/icons/stores/GooglePlay.vue';
import AppStore from '~/components/common/icons/stores/AppStore.vue';
import BImpersonated from '~/components/general/BImpersonated';
import BLanguageSwitcher from '~/components/general/BLanguageSwitcher.vue';
import BLoggedUser from '~/components/general/BLoggedUser.vue';

export default {
  name: 'BHeader',
  components: {
    IconMenu,
    IconSearch,
    BImpersonated,
    BLanguageSwitcher,
    BLoggedUser,
    GooglePlay,
    AppStore,
    IconBack,
    IconCancel
  },
  props: {
    isFixed: {
      type: Boolean,
      default: false
    },
    isDashboard: {
      type: Boolean,
      default: false
    },
    hasMaxWidth: {
      type: Boolean,
      default: false
    },
    padding: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      mobileMenu: false,
      showOrganizer: this.$enableOrganizers,
      searchTerm: '',
      isScrolled: false,
      isInfoPage: false,
      isDashboardInfoPage: false
    };
  },
  computed: {
    ...mapGetters('user', ['getDashboardUrl', 'getUserEmail']),
    ...mapGetters('app', ['userCanAccessDashboard', 'getOrganizer']),
    isLoggedIn() {
      return this.$auth.loggedIn;
    },
    isOrganizer() {
      return (
        this.$auth.loggedIn &&
        this.showOrganizer &&
        this.userCanAccessDashboard &&
        this.getOrganizer
      );
    },
    isImpersonated() {
      return this.$auth?.user?.impersonated_by;
    },
    defaultPadding() {
      return this.padding !== 0 ? `padding: 0 ${this.padding}px` : '';
    },
    infoPageTitle() {
      return this.isDashboardInfoPage
        ? this.$t('main.dashboard')
        : this.$t('main.profile');
    },
    infoPageText() {
      return this.isDashboardInfoPage
        ? this.$t('mobileMenu.infoDashboardPage.description')
        : this.$t('mobileMenu.infoProfilePage.description');
    }
  },
  mounted() {
    // check scroll top to add style on header
    if (!this.isDashboard) {
      window.addEventListener('scroll', this.handleScroll);
    }
    if (window.scrollY > 0) {
      this.isScrolled = true;
    }
  },
  methods: {
    redirectToDashboardInfoPage() {
      this.isInfoPage = true;
      this.isDashboardInfoPage = true;
    },
    redirectBack() {
      this.isInfoPage = false;
      this.isDashboardInfoPage = false;
    },
    redirectToGoogleStore() {
      window.open(this.$whitelabel.links.playStoreApp);
    },
    redirectToAppleStore() {
      window.open(this.$whitelabel.links.appStoreApp);
    },
    fetchEvents() {
      this.$store.commit('events/setSearchTerm', this.searchTerm);
      this.switchMenu();
    },
    switchMenu() {
      this.mobileMenu = !this.mobileMenu;
      this.isInfoPage = false;
      if (this.mobileMenu) {
        this.isScrolled = true;
      } else if (!this.mobileMenu && window.scrollY === 0) {
        this.isScrolled = false;
      }
    },
    handleScroll() {
      if (window.scrollY > 0) {
        this.isScrolled = true;
      } else if (!this.mobileMenu && window.scrollY === 0) {
        this.isScrolled = false;
      }
    },
    logout() {
      this.$auth.logout();
      this.$api.setHeader('Authorization', null);
      this.$store.commit('user/resetUser');
      this.$store.commit('app/clearAuthData');
      this.$store.dispatch('orders/clearOrders');
    },
    login() {
      this.$emit('openLogin');
    },
    redirectToProfile() {
      this.$router.push(this.localePath('/profile'));
      if (this.mobileMenu) {
        this.switchMenu();
      }
    },
    redirectToPassword() {
      this.$router.push(this.localePath('/profile/password'));
      this.switchMenu();
    },
    redirectToPrivacy() {
      this.$router.push(this.localePath('/profile/privacy'));
      this.switchMenu();
    },
    redirectToIndex() {
      // We use the path as it does not include the query string
      // We match it with regex so that we can support new locales easily
      const homePathRegex = /^\/[a-zA-Z]{2}$/;
      const isHomePath = homePathRegex.test(this.$router.currentRoute.path);
      if (isHomePath) {
        location.reload();
        return false;
      }
      this.$router.push(this.localePath('/'));
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/_scss/_utils/variables.scss';
@import '@/assets/_scss/_utils/mixins.scss';

.desktop {
  @include respond-below(sm) {
    display: none;
  }
}

.mobile {
  @include respond-above(sm) {
    display: none;
  }
}

.header {
  width: 100%;
  margin: 0px auto;
  display: grid;
  grid-template-columns: 180px 268px auto;
  align-items: center;
  padding: 0 64px;

  @include respond-below(sm) {
    padding: 0 20px !important;
    line-height: 130%;
    grid-template-columns: 180px auto;
  }

  &--maxWidth {
    max-width: $content-width;
  }

  &__account {
    padding: 2px;

    &-image {
      width: 36px;
      height: 36px;
      border-radius: 999px;
      object-fit: cover;
      border: 1px solid #fff;
      padding: 1px;
      opacity: 0.9;
      cursor: pointer;
      transition: $transition;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__menu {
    position: relative;

    nav {
      z-index: 999;
      top: 10px;
      right: 0;
      background-color: var(--v-backgroundPrimary-lighten5);
      border-radius: $border-radius;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      padding: 10px 0;
      transition: $transition;
      position: absolute;
      min-width: 120px;
    }
  }

  &__wrapper {
    position: fixed;
    display: grid;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height;
    z-index: 99;
    padding: 8px 0;

    &--dashboard {
      position: sticky;
      border-bottom: 1px solid var(--v-borderPrimary-lighten4);
      background-color: var(--v-backgroundGray-lighten2);

      .header {
        grid-template-columns: 268px auto;
        max-width: initial;

        &__link {
          color: var(--v-textPrimary-base);
        }
      }
    }

    &.active {
      background-color: var(--v-backgroundPrimary-base);
    }

    @include respond-below(sm) {
      padding: 16px 0;
    }
  }

  &__logo {
    cursor: pointer;

    img {
      height: 32px;
    }
  }

  &__search {
    display: none;

    input {
      padding: 10px 20px;
      line-height: 22px;
      font-size: 13px;
      border: 1px solid #fff;
      border-radius: 4px;
      color: var(--v-textPrimary-lighten5);

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: var(--v-textPrimary-lighten5);
      }
    }

    @include respond-below(sm) {
      display: block;
    }

    &-wrapper {
      display: grid;
      grid-template-columns: auto 46px;
      gap: 18px;
    }

    &-action {
      display: grid;
      background: #fff;
      width: 46px;
      height: 46px;
      border-radius: 4px;
      place-content: center;

      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  &__link {
    text-decoration: none;
    font-weight: 400;
    font-size: 13px;
    cursor: pointer;
    color: var(
      --v-textPrimary-lighten5
    ); // temporary fix TODO: remove vuetify structure from layout 2

    @include respond-below(sm) {
      padding: 24px 0;
      width: 100%;

      &:not(:nth-last-child(-n + 2)) {
        border-bottom: 0.5px solid var(--v-borderPrimary-lighten1);
      }

      &:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__nav {
    .footer {
      @include respond-above(sm) {
        display: none;
      }

      padding: 24px;
      display: block;
      color: #fff;
      background-color: var(--v-backgroundPrimary-lighten1);
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      height: 170px;

      &__title {
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        letter-spacing: 0.13px;
        margin-bottom: 16px;
      }

      &__content {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: 0.12px;
        margin-bottom: 16px;
      }

      &__image-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
      }
    }

    .links {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;
      justify-content: flex-end;

      @include respond-below(sm) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
      }
    }

    .button {
      border: 1px solid #fff;
    }

    @include respond-below(sm) {
      display: none;
      position: fixed;
      top: $header-height;
      padding: 0 24px 24px 24px;
      left: 0;
      width: 100%;
      background: var(--v-backgroundPrimary-base);
      bottom: 0;

      & > * {
        margin: 20px 0;
      }
    }

    &.active {
      display: block !important;
    }

    &--info {
      background-color: var(--v-backgroundPrimary-lighten5);
    }

    .info-page {
      &__title,
      &__text {
        color: var(--v-textPrimary-base);
      }
      &__back {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;
        margin-top: 16px;
        cursor: pointer;

        span {
          color: var(--v-textPrimary-base);
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          letter-spacing: 0.13px;
          text-decoration-line: underline;
        }
      }
    }
  }

  &__mobile {
    display: none;
    text-align: right;

    @include respond-below(sm) {
      display: block;
    }

    svg {
      opacity: 0.8;
      transition: $transition;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
