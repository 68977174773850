import { applyDiscount } from '../utils/pricing';

// TODO: Add proper types
export const state = () => ({
  orders: {}
});

export const mutations = {
  addOrder(state, payload) {
    state.orders = {
      ...state.orders,
      [payload.eventId]: {
        orderId: payload.orderId,
        orderData: payload.orderData,
        orderTimeout: payload.orderTimeout,
        market: payload.market
      }
    };
  },
  deleteOrder(state, payload) {
    delete state.orders[payload.eventId];
  },
  clearOrders(state) {
    state.orders = {};
  },
  addHolder(state, payload) {
    state.orders[payload.eventId].orderData.orderItem.forEach((oi) => {
      const ticket = oi.ticket.find((t) => t.id === payload.ticketId);
      if (ticket) {
        ticket.ticketHolder = payload.ticketHolder;
      }
    });
  },
  addOrderContact(state, payload) {
    state.orders[payload.eventId].orderContact = payload.orderContact;
  },
  updateOrderData(state, payload) {
    const ticketHolders = [];
    state.orders[payload.eventId].orderData.orderItem.forEach((oi) => {
      oi.ticket.forEach((ticket) => {
        ticketHolders.push(ticket.ticketHolder);
      });
    });
    state.orders[payload.eventId].orderData = payload.orderData;
    state.orders[payload.eventId].orderData.orderItem.forEach((oi) => {
      oi.ticket.forEach((ticket) => {
        if (ticket.ticketHolder) {
          const ticketHolder = ticketHolders.find(
            (th) => th?.id === ticket.ticketHolder.id
          );
          ticket.ticketHolder = {
            ...ticket.ticketHolder,
            ...ticketHolder
          };
        }
      });
    });
  },
  addPaymentType(state, payload) {
    state.orders[payload.eventId].paymentType = payload.paymentType;
  },
  addPaymentData(state, payload) {
    state.orders[payload.eventId].paymentData = payload.paymentData;
  }
};

export const getters = {
  getPaymentType: (state) => (id) => {
    return state.orders[id]?.paymentType;
  },
  getOrderByEventId: (state) => (id) => {
    return state.orders[id];
  },
  getOrderPaymentData: (state) => (id) => {
    return state.orders[id]?.paymentData;
  },

  /**
   * Return the order by ID and it's reduced tickets.
   *
   * Use the getOrderByEventId() getter to get the specific order and then
   * reduce the tickets to get the final price (faceValue - discount), and
   * add ticket holder to each ticket if needed.
   *
   * @param {Array} getters To enable the usage of another getters
   * @returns Order and reduced tickets
   */
  getReducedOrder: (state, getters) => (id) => {
    const order = getters.getOrderByEventId(id);
    // If there are no orders
    if (!order) return null;

    if (order.market === 'primary') {
      const tickets = order.orderData?.orderItem
        ?.flatMap((orderItem) => orderItem.ticket)
        .map((ticket) => {
          return {
            ...ticket,
            ticketHolder: ticket.ticketHolder ?? {},
            finalPrice: applyDiscount(
              ticket.ticketDiscount,
              ticket.ticketConfig.faceValue
            )
          };
        });

      return {
        ...order,
        tickets
      };
    } else {
      const tickets = order.orderData.order.offers.map((offer) => {
        return { ...offer, finalPrice: offer.price };
      });

      return {
        ...order,
        tickets
      };
    }
  }
};

export const actions = {
  clearOrders({ commit }) {
    commit('clearOrders');
  }
};
