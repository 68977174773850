<template>
  <v-app :style="config.layoutStyle">
    <client-only v-if="config.header">
      <BHeader is-fixed @openLogin="openLogin"></BHeader>
    </client-only>
    <v-content :class="{ 'content-container': config.header }">
      <client-only>
        <transition name="fade">
          <BAuth v-if="getAuthDialog" @close="closeAuthDialog"></BAuth>
        </transition>
      </client-only>
      <nuxt v-if="!$slots.default" @openLogin="openLogin" />
      <slot />
    </v-content>
    <BAlert></BAlert>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex';
import BAlert from '~/components/general/BAlert.vue';
import BHeader from '~/components/general/BHeader.vue';
import BAuth from '~/components/general/Auth/BAuth';
import {
  changePrimaryColorPalette,
  changeBackgroundColorPalette
} from '~/utils/utils';

export default {
  name: 'Default',
  components: {
    BAlert,
    BHeader,
    BAuth
  },
  data() {
    return {
      config: {
        header: true,
        primaryColorPalette: null,
        backgroundColorPalette: null,
        layoutStyle: null
      }
    };
  },
  computed: {
    ...mapGetters('app', ['getAuthDialog'])
  },
  created() {
    this.$nuxt.$on('closeForgotPasswordForm', this.closeAuthDialog);
  },
  beforeDestroy() {
    this.$nuxt.$off('closeForgotPasswordForm');
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const header = urlParams.get('header');
    if (header && header === 'false') {
      this.config.header = false;
    }
    const foreColor = urlParams.get('foreColor');
    if (foreColor) {
      this.config.primaryColorPalette = changePrimaryColorPalette(foreColor);
    }
    const backColor = urlParams.get('backColor');
    if (backColor) {
      this.config.backgroundColorPalette =
        changeBackgroundColorPalette(backColor);
    }
    this.config.layoutStyle = {
      ...this.config.primaryColorPalette,
      ...this.config.backgroundColorPalette
    };
  },
  methods: {
    closeAuthDialog() {
      this.$store.commit('app/setAuthDialog', false);
      this.$store.commit('app/setEnableGuestLogin', false);
      document.body.style = '';
    },
    openLogin() {
      this.$store.commit('app/setAuthDialog', true);
      document.body.style = 'height: 100vh; overflow-y: hidden';
    }
  }
};
</script>
<style lang="scss" scoped>
.content-container {
  margin-top: 32px;
}
</style>
