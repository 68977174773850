<template>
  <div :style="layoutStyle" class="layouts">
    <client-only v-if="config.showHeader">
      <BHeader
        :padding="padding"
        :has-max-width="!isBuyScreen"
        :is-fixed="!config.eventDescription"
        @openLogin="openLogin"
      ></BHeader>
    </client-only>
    <div class="layouts__container">
      <nuxt v-if="!$slots.default" @openLogin="openLogin" />
      <slot />
    </div>
    <BFooter
      :padding="padding"
      :has-max-width="!isBuyScreen"
      :color="footerColor"
    ></BFooter>
    <BAlert></BAlert>
    <BLoader v-show="getLoading"></BLoader>
    <client-only>
      <transition name="fade">
        <BAuth v-if="getAuthDialog" @close="closeAuthDialog"></BAuth>
      </transition>
    </client-only>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BHeader from '~/components/general/BHeader';
import BFooter from '~/components/general/BFooter';
import BLoader from '~/components/general/BLoader';
import BAuth from '~/components/general/Auth/BAuth';
import BAlert from '~/components/general/BAlert.vue';
import {
  changePrimaryColorPalette,
  changeBackgroundColorPalette
} from '~/utils/utils';

export default {
  name: 'Default',
  components: {
    BHeader,
    BFooter,
    BLoader,
    BAuth,
    BAlert
  },
  data() {
    return {
      themePath: this.$vuetify.theme.themes.light,
      config: {
        showHeader: true,
        eventDescription: true,
        primaryColorPalette: null,
        backgroundColorPalette: null,
        layoutStyle: null
      },
      foreColor: null
    };
  },
  computed: {
    ...mapGetters('app', ['getLoading', 'getAuthDialog']),
    padding() {
      if (this.isBuyScreen) {
        return 128;
      } else {
        return 20;
      }
    },
    isBuyScreen() {
      return (
        this.$route?.name?.includes('provider/id') ||
        this.$route?.name?.includes('provider-id') ||
        this.$route?.name?.includes('tenant/event/id') ||
        this.$route?.name?.includes('tenant-event-id')
      );
    },
    footerColor() {
      if (this.foreColor) {
        return `#${this.foreColor}`;
      }
      return this.themePath.backgroundPrimary.base;
    },
    isHomePage() {
      return this.$route?.name?.includes('index');
    },
    layoutStyle() {
      if (this.isHomePage) {
        return null;
      }
      return this.config.layoutStyle;
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const header = urlParams.get('header');
    if (header === 'false') {
      this.config.showHeader = false;
    }
    const description = urlParams.get('description');
    if (description === 'false') {
      this.config.eventDescription = false;
    }
    this.foreColor = urlParams.get('foreColor');
    if (this.foreColor) {
      this.config.primaryColorPalette = changePrimaryColorPalette(
        this.foreColor
      );
    }
    const backColor = urlParams.get('backColor');
    if (backColor) {
      this.config.backgroundColorPalette =
        changeBackgroundColorPalette(backColor);
    }
    this.config.layoutStyle = {
      ...this.config.primaryColorPalette,
      ...this.config.backgroundColorPalette
    };
  },
  methods: {
    closeAuthDialog() {
      this.$store.commit('app/setAuthDialog', false);
      this.$store.commit('app/setEnableGuestLogin', false);
      document.body.style = '';
    },
    openLogin() {
      this.$store.commit('app/setAuthDialog', true);
      document.body.style = 'height: 100vh; overflow-y: hidden';
    }
  }
};
</script>
<style lang="scss" scoped>
.layouts {
  background: var(--v-backgroundGray-lighten2);

  &__container {
    display: block;
    width: 100%;
  }

  &__auth {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);

    ::v-deep {
      .login-input {
        margin-bottom: 12px;
      }

      a.forgot-password.info-text {
        display: block;
        width: 100%;
        cursor: pointer;
        text-align: right;
        margin-bottom: 24px;
      }

      .v-tab {
        display: none;
      }

      .info-text {
        font-size: 12px;
        text-align: center;
        padding: 12px 0 0 0;
      }

      .spacer {
        display: none;
      }
    }
  }
}
</style>
