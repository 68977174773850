<template>
  <div ref="language-switcher" class="language-switcher">
    <div
      class="language-switcher--selected prevent-highlight"
      :class="{ 'closed-menu': !dropdown }"
      @click="onDropDown"
    >
      <img
        :src="require(`../common/icons/flags/${$i18n.locale}.svg`)"
        width="18px"
        class="language-switcher__flag"
        alt="Flag"
      />
      <span
        class="language-switcher__selected-language desktop"
        :style="`color: ${color}`"
        >{{ $i18n.locale.toUpperCase() }}</span
      >
      <span
        class="language-switcher__selected-language mobile"
        :style="`color: ${color}`"
        >{{ languageName }}</span
      >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="6"
        viewBox="0 0 9 6"
        fill="none"
      >
        <path d="M9 0.75L4.5 5.25L0 0.750001L9 0.75Z" :fill="`${color}`" />
      </svg>
    </div>
    <div
      v-show="dropdown"
      v-closable="{
        exclude: ['language-switcher'],
        handler: 'onClose'
      }"
      class="menu"
    >
      <ul class="menu__content">
        <li
          v-for="item in items"
          :key="item.code"
          class="menu__item prevent-highlight"
          @click="itemSelected(item.code)"
        >
          <img
            :src="require(`../common/icons/flags/${item.code}.svg`)"
            width="18px"
            alt="Flag"
          />
          <span class="menu__item--desktop">{{ item.code.toUpperCase() }}</span>
          <span class="menu__item--mobile">{{ item.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { errorMessage } from '~/utils/utils';

export default {
  name: 'BLanguageSwitcher',
  props: {
    light: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dropdown: false,
      items: this.$i18n.locales
    };
  },
  computed: {
    languageName() {
      const locale = this.$i18n.locales.find(
        (l) => l.code === this.$i18n.locale
      );
      return locale ? locale.name : this.$t('error.language.unknown');
    },
    availableLocales() {
      return this.$i18n.locales;
    },
    color() {
      return this.light
        ? 'var(--v-textPrimary-lighten5)'
        : 'var(--v-textPrimary-base)';
    },
    isLoggedIn() {
      return this.$auth.loggedIn;
    }
  },
  methods: {
    onDropDown() {
      this.dropdown = !this.dropdown;
    },
    async itemSelected(id) {
      if (this.isLoggedIn) {
        try {
          await this.$bamApi.account.updateUser(
            {
              id: this.$auth.user.id
            },
            { localeCode: id }
          );
        } catch (e) {
          this.$notifier.showMessage({
            content: errorMessage(e, this.$t('errors.saveUserInfo')),
            type: 'error'
          });
        }
      }
      this.$i18n.locale = id;
      this.dropdown = false;
      this.$router.push({ path: this.switchLocalePath(id) });
    },
    onClose() {
      this.dropdown = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/_scss/_utils/variables.scss';
@import '@/assets/_scss/_utils/mixins.scss';

.desktop {
  @include respond-below(sm) {
    display: none;
  }
}

.mobile {
  @include respond-above(sm) {
    display: none;
  }
}

.language-switcher {
  @include respond-above(sm) {
    display: inline-block;
    box-sizing: border-box;
    width: 72px;

    &--selected {
      display: grid;
      grid-template-columns: 18px auto 9px;
      gap: 8px;
      align-items: center;
    }

    font-family: inherit;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.12px;
    cursor: pointer;

    .menu {
      display: inline-block;
      box-sizing: border-box;
      width: 72px;
      margin-top: 6px;

      position: absolute;
      z-index: 24;
      border-radius: 4px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      filter: drop-shadow(0px 7px 15px rgba(37, 42, 47, 0.1))
        drop-shadow(0px 4px 16px rgba(37, 42, 47, 0.05));

      &__content {
        padding: 0 !important;

        span {
          color: var(--v-textPrimary-base);
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &__item {
        &--desktop {
          display: block;
        }

        &--mobile {
          display: none;
        }

        display: flex;
        height: 40px;
        padding: 12px 16px 12px 8px;
        align-items: center;
        justify-content: space-around;
        background: var(--v-backgroundPrimary-lighten5);

        &:hover {
          background-color: var(--v-backgroundPrimary-lighten4);
        }

        &:first-child {
          border-radius: 4px 4px 0px 0px;
        }

        &:last-child {
          border-radius: 0px 0px 4px 4px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid var(--v-borderPrimary-lighten4);
        }
      }
    }
  }

  @include respond-below(sm) {
    // prevent v-app to apply default styles
    ul {
      padding: 0;
    }

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.12px;
    color: var(--v-textPrimary-lighten5);

    position: absolute;
    left: 0;
    right: 0;
    bottom: 170px;

    &__flag {
      margin-right: 8px;
    }

    &--selected {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 6px;

      &.closed-menu {
        margin-bottom: 24px;
      }
    }

    &__selected-language {
      margin-right: 16px;
    }

    .menu {
      box-sizing: border-box;
      z-index: 24;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      filter: drop-shadow(0px 7px 15px rgba(37, 42, 47, 0.1))
        drop-shadow(0px 4px 16px rgba(37, 42, 47, 0.05));

      &__content {
        span {
          color: var(--v-textPrimary-base);
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          letter-spacing: 0.12px;
        }
      }

      &__item {
        &--desktop {
          display: none;
        }

        &--mobile {
          display: block;
        }

        display: flex;
        height: 40px;
        padding: 12px 16px 12px 8px;
        align-items: center;
        justify-content: center;

        gap: 8px;
        background: var(--v-backgroundPrimary-lighten5);

        &:hover {
          background: var(--v-backgroundPrimary-lighten4);
        }

        &:not(:last-child) {
          border-bottom: 1px solid var(--v-borderPrimary-lighten4);
        }
      }
    }
  }
}
</style>
