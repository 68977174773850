import { BigNumber } from 'bignumber.js';
import { TicketDiscount } from '@bam/sdk';
import { DISCOUNT_TYPES } from './utils';

/**
 * @param {TicketDiscount} discount ticket discount
 * @param {number} price regular price
 */
export const applyDiscount = (discount: TicketDiscount, price: number) => {
  if (discount == null) {
    return price;
  }

  const priceBN = BigNumber(price);
  let result = priceBN;

  switch (discount.type) {
    case DISCOUNT_TYPES.AMOUNT: {
      result = priceBN.minus(discount.value);
      break;
    }
    case DISCOUNT_TYPES.PERCENTAGE: {
      result = priceBN
        .multipliedBy(BigNumber(100).minus(discount.value))
        .dividedBy(100);
      break;
    }
  }
  // Don't go below 0
  result = result.gte(0) ? result : BigNumber(0);

  return result.toNumber();
};
