<template>
  <div
    v-if="email"
    ref="logged-user"
    v-click-outside="() => onClose()"
    class="logged-user prevent-highlight"
    :style="`background-color: ${backgroundColor}`"
  >
    <div class="logged-user__email" @click="onDropDown">
      <div :style="{ color }" class="logged-user__profile">
        <span class="MOBILE_h13_Regular logged-user__profile-label">{{
          $t('main.profile')
        }}</span>
        <span
          class="MOBILE_h13_Regular logged-user__profile-email"
          :style="{ color }"
          data-cy="loggedInUserDropdown"
          >{{ email }}</span
        >
      </div>
      <div @click.stop="onDropDown">
        <component
          :is="dropdown ? 'IconSelectUp' : 'IconSelectDown'"
          :color="color"
        />
      </div>
    </div>
    <div
      v-show="dropdown"
      v-closable="{
        exclude: ['logged-user'],
        handler: 'onClose'
      }"
      class="menu"
    >
      <ul class="menu__content prevent-highlight desktop-version">
        <li
          class="menu__item"
          data-cy="profileButton"
          @click="$emit('profile')"
        >
          <span>{{ $t('main.profile') }}</span>
        </li>
        <li class="menu__item" data-cy="logoutButton" @click="$emit('logout')">
          <span>{{ $t('main.logout') }}</span>
        </li>
      </ul>
      <ul class="menu__content prevent-highlight mobile-version">
        <li class="MOBILE_h13_Regular menu__item" @click="$emit('profile')">
          <span>{{ $t('profile.general') }}</span>
        </li>
        <li class="MOBILE_h13_Regular menu__item" @click="$emit('password')">
          <span>{{ $t('profile.password') }}</span>
        </li>
        <li class="MOBILE_h13_Regular menu__item" @click="$emit('privacy')">
          <span>{{ $t('sideMenu.profile.privacy') }}</span>
        </li>
        <li class="MOBILE_h13_Regular menu__item" @click="$emit('logout')">
          <span>{{ $t('main.logout') }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import IconSelectDown from '@/components/general/icons/IconSelectDown.vue';
import IconSelectUp from '@/components/general/icons/IconSelectUp.vue';
export default {
  name: 'BLoggedUser',
  components: {
    IconSelectDown,
    IconSelectUp
  },
  props: {
    light: {
      type: Boolean,
      default: true
    },
    email: {
      type: String,
      default: '',
      required: true
    }
  },
  data() {
    return {
      dropdown: false,
      maxCharacters: 15 // Set the maximum number of characters in the email
    };
  },
  computed: {
    color() {
      return this.light
        ? this.$whitelabel.lightTheme.textPrimary.lighten5
        : this.$whitelabel.lightTheme.textPrimary.base;
    },
    backgroundColor() {
      return this.light
        ? this.$whitelabel.lightTheme.backgroundPrimary.lighten1
        : this.$whitelabel.lightTheme.backgroundGray.lighten1;
    }
  },
  methods: {
    onDropDown() {
      this.dropdown = !this.dropdown;
    },
    onClose() {
      this.dropdown = false;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/_scss/_utils/variables.scss';
@import '@/assets/_scss/_utils/mixins.scss';

.logged-user {
  &__profile {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  @include respond-above(sm) {
    display: inline-block;
    box-sizing: border-box;
    width: 166px;
    border-radius: 4px;

    .desktop-version {
      display: block;
    }

    .mobile-version {
      display: none;
    }

    &__profile-label {
      display: none;
    }

    &__email {
      display: grid;
      grid-template-columns: 120px auto;
      gap: 16px;
      align-items: center;
      padding: 6px 10px;
    }

    font-family: inherit;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.13px;
    cursor: pointer;

    .menu {
      display: inline-block;
      box-sizing: border-box;
      width: 166px;
      margin-top: 6px;
      position: absolute;
      z-index: 24;
      border-radius: 4px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      filter: drop-shadow(0px 7px 15px rgba(37, 42, 47, 0.1))
        drop-shadow(0px 4px 16px rgba(37, 42, 47, 0.05));

      &__content {
        padding: 0 !important;

        span {
          display: -webkit-box;
          color: var(--v-textPrimary-base);
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 8px;
        }
      }

      &__item {
        list-style-type: none;
        text-decoration: none;
        display: flex;
        height: 40px;
        padding: 12px 16px 12px 8px;
        background: var(--v-backgroundPrimary-lighten5);

        &:hover {
          background-color: var(--v-backgroundPrimary-lighten4);
        }

        &:first-child {
          border-radius: 4px 4px 0px 0px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid var(--v-borderPrimary-lighten4);
        }

        &:last-child {
          border-radius: 0px 0px 4px 4px;
        }
      }
    }
  }

  @include respond-below(sm) {
    width: 100%;

    // prevent v-app to apply default styles
    ul {
      margin: 0;
      padding: 0;
    }

    .desktop-version {
      display: none;
    }

    .mobile-version {
      display: block;
    }

    &__email {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 24px;
      background-color: var(--v-backgroundPrimary-base);
    }

    &__profile-label {
      color: var(--v-textPrimary-lighten5);
      border-right: 1px solid var(--v-borderPrimary-lighten1);
      padding-right: 12px;
    }

    &__profile-email {
      padding-left: 12px;
    }

    .menu {
      box-sizing: border-box;
      background-color: var(--v-backgroundPrimary-base);

      &__item {
        list-style-type: none;
        text-decoration: none;
        display: flex;
        color: var(--v-textPrimary-lighten5);
        padding-top: 16px;
      }
    }
  }
}
</style>
