const PROTOCOL = 'https';

export default (baseUrl: string) => ({
  accountServiceUrl() {
    return `${PROTOCOL}://${baseUrl}/account/v1`;
  },

  websocketServiceUrl(tenant: string) {
    return `${PROTOCOL}://${tenant}.${baseUrl}/websocket/v1`;
  }
});
